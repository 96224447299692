import React, { useRef } from 'react';
import useFadeIn from '@src/hooks/useFadeIn';
import styles from './index.module.scss';
import Tag from '@src/components/Tag';
import SectionTitle from '@src/components/SectionTitle';
import SectionContent from '@src/components/SectionContent';
import Button from '@src/components/Button';
import {
  DiscordLink,
  GithubBaseLink,
} from "@src/constant/navLinks";

const communityLink = DiscordLink;
const title = "Get started building with Primus";
const content =
  "We welcome developers, data providers, workers, and callers to collaboratively build the network. Integrate verifiable confidential computation into your app with developer friendly SDKs. ";

export default () => {
    const ref = useRef(null);
    const [visible] = useFadeIn(ref);
    return (
      <div className={styles.root}>
        <div className={styles.img} />
        <div className={styles.wrapper}>
          {/* <Tag>Build together</Tag> */}
          <SectionTitle
            transition
            visible={visible}
            ref={ref}
            className={styles.title}
          >
            {title}
          </SectionTitle>
          <SectionContent
            transition
            visible={visible}
            className={styles.content}
          >
            {content}
          </SectionContent>
          <div className={styles.footer}>
            <Button link={`${GithubBaseLink}`} className={styles.btn} isDark>
              Start Building
            </Button>
            {/* <Button
              link={communityLink}
              className={styles.btn}
              isDark
              type="secondary"
            >
              Join Community
            </Button> */}
          </div>
        </div>
      </div>
    );
};