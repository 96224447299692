import React, { forwardRef } from "react";
import {  DiscordLink,devHugLink } from "@src/constant/navLinks";
import cn from "classnames";
import Button from "@src/components/Button";
import headerBG from "./header-bg.svg";
import bgReactangleExtension1 from "./bgReactangleExtension1.svg";
import bgReactangleExtension2 from "./bgReactangleExtension2.svg";
import bgReactangleExtension3 from "./bgReactangleExtension3.svg";
import styles from "./index.module.scss";

export default forwardRef((props, headerRef) => {
  return (
    <div ref={headerRef} className={styles.header}>
      <div className={styles.container}>
        <div className={styles.headerBGWrapper}>
          <div className={styles.headerBG}></div>
          <div className={styles.bgRectangle1}>
            <img
              src={bgReactangleExtension1}
              className={styles.bgRectangleExtension1}
            />
          </div>
          <div className={styles.bgRectangle2}>
            <img
              src={bgReactangleExtension2}
              className={styles.bgRectangleExtension2}
            />
          </div>
          <div className={styles.bgRectangle3}>
            <img
              src={bgReactangleExtension3}
              className={styles.bgRectangleExtension3}
            />
          </div>
        </div>

        <div className={styles.contentWrapper}>
          <div className={cn("flex", styles.content)}>
            <div className={styles.title}>
              Make the world’s data useful onchain
            </div>
            <div className={styles.subTitle}>
              Primus lets you verify and compute any data in web3.
            </div>
            {/* <div className={styles.desc}>
              <a href="" target="_blank">Learn about</a>&nbsp; zkFHE
              (zero knowledge fully homomorphic encryption) and zkTLS (secure
              multi-party computation with transport layer security) for data
              verification and computation.
            </div> */}
          </div>
          <div className={cn("flex", styles.btnWrapper)}>
            <Button
              link={`${devHugLink}`}
              className={cn(styles.btn)}
            >
              Start Building
            </Button>
            <Button link={DiscordLink} className={styles.btn} type="secondary">
              Join Community
            </Button>
          </div>
          <div className={cn(styles.rectangle, styles.small)} />
          <div className={cn(styles.rectangle, styles.big)} />
        </div>
      </div>
    </div>
  );
});
